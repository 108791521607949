require('./bootstrap');

let $form = $('#forms');


if ($form.length) {
    let $checkBox = $form.find('input[type="checkbox"]');
    let embed = $checkBox.parents('.col-md-6').next().children('.input-item');

    if ($checkBox.is(':checked'))
        embed.hasClass('hidden') ? embed.removeClass('hidden').addClass('show') : '';

    $form.on('click', function () {
        if ($checkBox.is(':checked')) {
            embed.hasClass('hidden') ? embed.removeClass('hidden').addClass('show') : '';
        } else {
            embed.hasClass('show') ? embed.removeClass('show').addClass('hidden') : '';
        }
    })
}
